<template>
  <b-card title="Near By">
    <b-tabs>
      <b-tab
        active
        @click="$router.push('/add-country-pages')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Near By</span>
        </template>
      
      </b-tab>
      <b-tab @click="$router.push('/all-country-pages')">
        <template #title>
          <feather-icon icon="HomeIcon" data-testid="all-nearby"/>
          <span>  Near By</span>
        </template>
    
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      
      
      <router-view />
    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import Addcountrypages from './Addcountrypages.vue'

export default {
  components: {
    BCard,

    BTabs,
    BTab,

    Addcountrypages,
  },
  data() {
    return {

    }
  },
}
</script>
